<template>
  <validation-observer ref="categoryForm" tag="form" @submit.prevent="onSubmit">
    <b-form>
      <b-row>
        <b-col cols="12">
          <b-form-group
            class="row"
            label="Name"
            label-for="name"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Name"
              vid="category.name"
            >
              <b-form-input
                @blur="presetSlug"
                v-model="category.name"
                id="name"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <div class="dropdown-container">
            <div class="main-dropdown">
              <b-form-group
                class="row"
                label="Parent category"
                label-for="parent"
                label-cols-md="2"
                content-cols-lg="4"
              >
                <v-select
                  v-model="category.parent"
                  :options="categories"
                  name="parent"
                  class="custom-select"
                  :clearable="false"
                  :searchable="false"
                >
                </v-select>
              </b-form-group>
            </div>
          </div>

          <b-form-group
            class="row"
            label="Url"
            label-for="url"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Url"
              vid="category.url"
            >
              <b-form-input
                @blur="slugifyURL"
                v-model="category.url"
                id="url"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            class="row"
            label="Description"
            label-for="description"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <b-form-textarea
              id="description"
              name="Description"
              v-model="category.description"
            />
          </b-form-group>
            <b-form-group
                class="row"
                label="Enabled"
                label-for="h-active"
                label-cols-md="2"
                content-cols-lg="4"
            >
                <b-form-checkbox v-model="category.enabled" id="h-active" switch/>
            </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Toast from '@/components/Toast.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    categoryObject: { Object, required: true },
    action: { type: String, default: null },
  },
  data() {
    return {
      category: this.categoryObject,
      categories: [],
    }
  },
  watch: {
    action() {
      this.onSubmit()
    },
  },
  created() {
    const headers = {
      fields: '"/hydra:member/*/@id", "/hydra:member/*/name"',
    }
    this.$Categories
      .getCollection({ headers, params: { pagination: false } })
      .then(response => {
        response.data['hydra:member'].forEach(category => {
          if (category['@id'] !== this.category['@id']) {
            this.categories.push({
              label: category.name,
              id: category['@id'],
            })
          }
          if (this.category.parent) {
            if (this.category.parent === category['@id']) {
              this.category.parent = {
                label: category.name,
                id: this.category.parent,
              }
            }
          } else {
            this.category.parent = { label: 'Not selected', id: null }
          }
        })
      })
  },
  methods: {
    presetSlug() {
      if (this.category.url.length === 0) {
        this.category.url = this.$helper.slugifyURL(this.category.name)
      }
    },
    slugifyURL() {
      if (this.category.url.length !== 0) {
        this.category.url = this.$helper.slugifyURL(this.category.url)
      }
    },
    async onSubmit() {
      const isValid = await this.$refs.categoryForm.validate()
      if (isValid) {

        if (this.category.parent) {
          this.category.parent = this.category.parent.id
        }

        if (this.action === 'add') {
          this.$Categories
            .createResource({ body: this.category })
            .then(response => {
              if (response.status === 201) {
                this.$helper.showToast(
                  this.$toast,
                  Toast,
                  'New category created',
                  '',
                  'success',
                )
                this.$router.push(`/categories`)
              }
            })
            .catch(error => {
              if (error.response) {
                this.$emit('clearAction')
                this.$helper.showToast(
                  this.$toast,
                  Toast,
                  'Failed to create new category',
                  '',
                  'danger',
                )
              }
            })
        }

        if (this.action === 'update') {
          this.$Categories
            .updateResource({ id: this.category.id, body: this.category })
            .then(response => {
              if (response.status === 200) {
                this.$helper.showToast(
                  this.$toast,
                  Toast,
                  'Category updated',
                  '',
                  'success',
                )
                this.$router.push(`/categories`)
              }
            })
            .catch(error => {
              if (error.response) {
                this.$emit('clearAction')
                this.$helper.showToast(
                  this.$toast,
                  Toast,
                  'Failed to update category',
                  '',
                  'danger',
                )
              }
            })
        }
      } else {
        this.$emit('clearAction')
      }
    },
  },
}
</script>
