<template>
  <div class="add-new-category">
    <title-bar
      submitBtn
      submitBtnTitle="Update category"
      title="Save category"
      @submitPressed="submitPressed"
    />
    <category-form v-if="category" :categoryObject="category" :action="action" @clearAction="action = null" />
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import CategoryForm from './components/CategoryForm.vue'
import router from '@/router'

export default {
  components: {
    TitleBar,
    CategoryForm
  },
  data() {
    return {
      action: null,
      category: null
    }
  },
  created() {
    this.loadCategory()
  },
  methods: {
    loadCategory() {
      const { id } = router.currentRoute.params
      this.$Categories.getResource({ id }).then(response => {
        this.category = response.data
      })
    },
    submitPressed() {
      this.action = 'update'
    }
  },
}
</script>
